import React from 'react';
import { path } from 'lodash/fp';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import { Heading, Text as RebassText } from 'rebass';
import { css } from '@emotion/react';

const SMALL_FONT_SIZE = '13px';

// it is the same as H3 but without text being bold
let InfoTextAsH2 = styled.div`
  color: ${props => props.color || props.theme.colors.light};
  font-size: 21px;
  line-height: 26px;
  margin-bottom: ${props => props.marginBottom}px;
`;

InfoTextAsH2 = withTheme(InfoTextAsH2);

export { InfoTextAsH2 };

const StyledSmall = styled.div`
  font-size: ${SMALL_FONT_SIZE};
  color: ${props => props.color || '#000'};
`;

export const SmallStyle = props => css`
  font-size: ${SMALL_FONT_SIZE};
  color: ${props.color};
`;

export const Small = withTheme(({ theme, type, color, children }) => {
  return (
    <StyledSmall
      color={color ? color : theme.colors.text.light}
      as={type ? type : 'small'}
    >
      {children}
    </StyledSmall>
  );
});

export const P = ({ type, children, ...props }) => {
  return (
    <RebassText
      as={type ? type : 'p'}
      fontSize={2}
      lineHeight="24px"
      {...props}
    >
      {children}
    </RebassText>
  );
};

export const H4 = ({ type, children, ...props }) => {
  return (
    <Heading as={type ? type : 'h4'} fontSize={2} {...props}>
      {children}
    </Heading>
  );
};

const textStyles = props => css`
  word-break: break-word;
  line-height: ${props.lineHeight || 21}px;
  color: ${props.color
    ? props.theme.colors.text[props.color] || props.color
    : props.theme.colors.text.light};
  font-size: ${props.fontSize
    ? props.theme.fontSizes[props.fontSize] || `${props.fontSize}px`
    : props.theme.fontSizes[2]}px;
  font-weight: ${props.bold ? 'bold' : 'normal'};
`;

export const StandardHeading = styled(Heading)`
  ${props => textStyles({ ...props, fontSize: props.fontSize || 32 })};
`;

// TODO: reuse these for standard text as well
const StandardTextBase = styled(RebassText)`
  word-break: break-word;
  cursor: ${props => (props.clickable ? 'pointer' : 'inherit')};
  line-height: ${props => props.lineHeight || '1.4'};
  color: ${props => {
    if (props.rColor) {
      return path(props.rColor)(props.theme.colors);
    }

    return props.color
      ? props.theme.colors.text[props.color] || props.color
      : props.theme.colors.neutral800;
  }};
  font-size: ${props =>
    props.fontSize
      ? props.theme.fontSizes[props.fontSize] || `${props.fontSize}px`
      : props.theme.fontSizes[2]}px;
  font-weight: ${props => (props.bold ? 'bold' : props.fontWeight || '')};
  text-align: ${props => props.textAlign || 'left'};
  display: ${props => (props.inline ? 'inline' : 'block')};

  em {
    font-style: italic;
  }
`;

const TextResponsiveBase = styled(StandardTextBase)`
  text-align: center;
  ${props => props.theme.mq.md} {
    text-align: left;
  }
`;

export const TextResponsive = withTheme(TextResponsiveBase);

export const StandardText = withTheme(StandardTextBase);

export {
  HeadingBase,
  H1,
  H2,
  H3,
  Subheading,
  StandFirst,
  P1,
  P2,
  P3,
  Caption,
} from './rebranded';
